import React, { useEffect, useReducer, useState, useContext } from 'react';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import { quoteContext } from '../../../provider';

import axios from 'axios';
import '../../assets/styles/pages/quote/step-four.scss';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ProgressBar from '../../components/quotes/progress-bar';
import QuoteNav from '../../components/quotes/quote-nav';
import BackArrow from '../../images/quote/icon-arrow-left.inline.svg';
import CloseIcon from '../../images/icon-close.inline.svg';

/* global window */
const QuoteStepFour = () => {
  const data = useStaticQuery(graphql`
    query {
      licensePlate: file(relativePath: { eq: "quote/license-plate.png" }) {
        childImageSharp {
          fluid(maxWidth: 122) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      quotepage {
        licensePlate {
          photoLink {
            local {
              childImageSharp {
                fluid(maxWidth: 245, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        licenseStateList {
          text
          value
        }
      }
    }
  `);
  const [repairDetails, setRepairDetails] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      repairLocationVal: '',
      repairLocation: [],
      carRegisteredIn: '',
      vehicleRegistrationNumber: ''
    }
  );

  const { licenseStateList } = data.quotepage;
  const { licensePlate } = data.quotepage;
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [wrongFormUrl, setWrongFormUrl] = useState(false);
  const [loadingPostcode, setLoadingPostcode] = useState(false);
  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const [hideSuggestions, setHideSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [modalObj, setModalObj] = useState([]);
  let { quoteForm } = useContext(quoteContext);
  const letterNumber = /^[0-9a-zA-Z]+$/;

  useEffect(() => {
    if (Object.keys(quoteForm).length > 0) {
      setRepairDetails(quoteForm.repairDetails);
    } else {
      navigate('/quote');
    }
  }, [quoteForm]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRepairDetails({ [name]: value });
  };

  const handleRegChange = (event) => {
    let { value } = event.target;
    const { name } = event.target;

    if (value.includes(" ")) {
      value = value.replace(/\s/g, "");
    }

    setRepairDetails({ [name]: value });
  };

  const handleRegKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }

    if (!e.key.match(letterNumber)) {
      e.preventDefault();
    }
  };

  const handleSubmit = (event, context) => {
    setLoading(true);
    event.preventDefault();

    if (!hasRepairDetails() || wrongFormUrl) {
      setLoading(false);
      return false;
    }

    axios.post(`${process.env.GATSBY_PORT}endpoints/registrationlookup`,
      { 'registration': `${repairDetails.vehicleRegistrationNumber}`, 'state': `${repairDetails.carRegisteredIn}` }
    ).then(res => {
      const { data } = res;
      if (data.success) {
        setModalObj(data.data);
        context.addToQuoteForm({
          ...quoteForm,
          repairDetails: repairDetails,
          nevdis_id: data.data.nevdis_id
        });
        setConfirmPopUp(true);
      } else if (data.data.code === 'E04') {
        setWrongFormUrl(data.data.formUrl);
      } else {
		setErrorMessage(true);
	  }
      setLoading(false);
    }).catch((error) => {
      console.warn(error);
      setErrorMessage(true);
      setLoading(false);
    });
  };

  const fetchSuburbPostcode = (event) => {
    setLoadingPostcode(true);
    const { value } = event.target;
    setRepairDetails({ 'repairLocationVal': value });

    if (value.length >= 3) {
      axios.post(`${process.env.GATSBY_PORT}endpoints/postcodelookup`,
        { 'postcode': value }
      ).then(res => {
        setSuggestions([]);
        const { data } = res;
        if (data.success) {
          setSuggestions(data.data);
          setLoadingPostcode(false);
        } else {
          setSuggestions([]);
          setLoadingPostcode(false);
        }
      });
    }

    handleInputChange(event);
  };

  const chooseSuburb = (val) => {
    setHideSuggestions(true);
    setRepairDetails({ 'repairLocation': val });
    setRepairDetails({ 'repairLocationVal': `${val.postcode} - ${val.suburb}` });
  };

  const hasRepairDetails = () => {
    let valid = true;
    if (repairDetails.repairLocation.length === 0) {
      valid = false;
    }
    if (repairDetails.carRegisteredIn.length === 0) {
      valid = false;
    }
    if (repairDetails.vehicleRegistrationNumber.length === 0) {
      valid = false;
    }

    return valid;
  };

  return (
    <Layout wrapperClass="quote-container" hideHeaderFooter={true}>
      <quoteContext.Consumer>
        {context => (
          <React.Fragment>
            <ProgressBar step="4" />
            <SEO title="DingGo | Car Quote - Submit Your Car Details" description="Upload your photos and describe the damage to compare car repair quotes online, fast and free. Snap. Compare. Repair." />
            <div className="quote-wrapper step-four">
              <div className="container xs">
                <QuoteNav step="4" />
                <h1>Repair Details</h1>
                <p className="intro">
                  Almost finished. Enter a convenient location for your repair and please confirm your car make & model.
                </p>

                <form onSubmit={(e) => { handleSubmit(e, context); }} autoCorrect="off" spellCheck="false" autoComplete="off">
                  <div className="form-group form-group-suggestion">
                    <label>Your preferred repair location</label>
                    <div className="form-group-suggestion">
                      <input
                        type="text"
                        className="form-control"
                        id="repairLocationVal"
                        autoCorrect="off"
                        spellCheck="false"
                        autoComplete="off"
                        name="repairLocationVal"
                        placeholder="Type Postcode or Suburb"
                        value={repairDetails.repairLocationVal}
                        onChange={(e) => fetchSuburbPostcode(e)}
                        onFocus={() => setHideSuggestions(false)}
                        
                      />
                      {loadingPostcode && (
                        <div className="lds-ring">
                          <div />
                          <div />
                          <div />
                          <div />
                        </div>
                      )}
                    </div>
                    {suggestions.length > 0 && (
                      <ul className={`suggestion-wrapper ${hideSuggestions ? 'hide' : ''}`}>
                        {suggestions.map((suggestion) => (
                          <li key={`${suggestion.postcode}-${suggestion.suburb}`} onClick={(e) => { chooseSuburb(suggestion) }}>
                            {suggestion.postcode} - {suggestion.suburb}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Car registered In</label>
                    <select className="form-control" value={repairDetails.carRegisteredIn} id="carRegisteredIn" name="carRegisteredIn" onChange={(e) => { handleInputChange(e); }}>
                      <option value="" disabled>Please select</option>
                      {licenseStateList.map((node) => (
                        <option key={node.text} value={node.value} name={node.text}>{node.text}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Vehicle registration number</label>
                    <input
                      type="text"
                      className="form-control reg-number"
                      id="vehicleRegistrationNumber"
                      name="vehicleRegistrationNumber"
                      onKeyDown={(e) => { handleRegKeyDown(e); }}
                      onChange={(e) => { handleRegChange(e); }}
                      value={repairDetails.vehicleRegistrationNumber}
                    />
                    <div className="icense-plate-wrapper">
                      <div className="image-wrapper">
                        {licensePlate.photoLink.local && (
                          <Img fluid={licensePlate.photoLink.local.childImageSharp.fluid} className="license plate example" />
                        )}
                      </div>
                      <span>
                        We need your rego to confirm your vehicle make and model for the repairer.
                      </span>
                    </div>
                  </div>
                  {errorMessage && (
                    <p className="error">
                      Something has gone wrong with your submission, please make sure your registration number is one word, without spaces or dashes. Then please try again.
                    </p>
                  )}
					{wrongFormUrl && (
						<p className="error">
							This vehicle belongs to a fleet. Unfortunately, the form you are using is designed for 
							retail customers, which does not contain the information we are required to collect. 
							Please use the fleet form available at: <a href={wrongFormUrl}>{wrongFormUrl}</a>
						</p>
					)}
                  <div className="form-btn-wrapper">
                    <Link to="/quote/step-three" className="arrow-cta"><BackArrow />Back</Link>
                    <button className={`btn ${hasRepairDetails() && !wrongFormUrl ? '' : 'disable'}`} type="submit">
                      {loading && (
                        <div className="lds-ellipsis">
                          <div />
                          <div />
                          <div />
                          <div />
                        </div>
                      )}
                      {!loading && (
                        <span>Next</span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div
              className={`popup-overlay ${confirmPopUp ? 'active' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                setConfirmPopUp(!confirmPopUp);
              }}
            />
            <div className={`popup-wrapper text-center ${confirmPopUp ? 'active' : ''}`}>
              <CloseIcon className="close-icon"
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmPopUp(!confirmPopUp);
                }} />
              <h2 className="popup-title">Confirm Vehicle.</h2>
              <p className="popup-intro">
                The vehicle registration number you entered is listed as the following vehicle.
              </p>
              <div className="car-details">
                <span className="car-type">
                  {modalObj.make} {modalObj.model} {modalObj.year} {modalObj.color} {modalObj.body}
                </span>
                <span className="car-registration">
                  Vehicle Registration: <strong className="reg-number">{modalObj.registration}</strong>
                </span>
              </div>
              <Link to="/quote/step-five" className="btn" id="button-click-to-step-5">Yes, thats my car!</Link>
              <span className="link-back"
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmPopUp(!confirmPopUp);
                }}>No, re-enter registration details</span>
            </div>
          </React.Fragment>
        )}
      </quoteContext.Consumer>
    </Layout>
  );
};

export default QuoteStepFour;
